<template>
  <div>
    <v-btn
      :elevation="flat ? 0 : 2"
      @click="backAction"
      :small="small"
      color="primary darken-2"
      :class="classes"
    >
      Back
    </v-btn>
    <v-dialog v-model="dialog" max-width="500">
      <v-card flat tile>
        <v-toolbar flat dark>
          <v-toolbar-title>Unsaved Changes</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-4">
          You have unsaved changes in this page. Are you sure you want to exit?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false;" color="primary darken-2">Cancel</v-btn>
          <v-btn @click="back" color="accent">Exit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    backRoute: Object,
    unsavedChanges: Boolean,
    small: {
      required: false,
      type: Boolean,
      default: true,
    },
    flat: {
      required: false,
      type: Boolean,
      default: true,
    },
    classes: {
      required: false,
      default: {},
    },
  },
  computed: {
    backAction() {
      return this.unsavedChanges ? this.prompt : this.back;
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    back() {
      this.$router.push(this.backRoute);
    },
    prompt() {
      this.dialog = true;
    },
  },
};
</script>
