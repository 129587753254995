<template>
  <base-component :component="component" :dashboard="dashboard">
    <template v-slot:content>
      <v-dialog
        max-width="800"
        v-model="dialog"
        scrollable
      >
        <template v-slot:activator="{ on }">
          <img
            :src="configuration.url"
            :style="imgStyle"
            :alt="component.subtitle || component.title"
            v-on="on"
          >
        </template>
        <v-card tile>
          <v-toolbar flat color="primary darken-3" dark>
            {{ component.title }}
            <v-subheader v-if="component.subtitle">{{ component.subtitle }}</v-subheader>
            <v-spacer></v-spacer>
            <v-btn icon @click="(dialog = false)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="justify-center text-center pt-4">
            <img
              :src="configuration.url"
              :style="imgStyle"
              :alt="component.subtitle || component.title"
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </base-component>
</template>

<script>
import dashboardComponentHelpers from '@/mixins/dashboard-component-helpers.js';

export default {
  name: 'ImageComponent',
  mixins: [
    dashboardComponentHelpers,
  ],
  data: () => ({
    dialog: false,
  }),
  computed: {
    configuration() {
      return this.component?.configuration;
    },
    imgStyle() {
      return {
        height: this.configuration.height || undefined,
        width: this.configuration.width || undefined,
        cursor: this.configuration.clickToOpen ? 'pointer' : undefined,
        'max-height': '100%',
        'max-width': '100%',
      };
    },
  },
  methods: {
    onClick() {
      if (this.configuration.clickToOpen) {
        this.dialog = true;
      }
    },
  },
};
</script>
