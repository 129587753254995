<template>
  <div>
    <notifications style="font-family: Roboto" group="default"/>
    <router-view :key="$route.fullPath" v-if="!loadingInitialState"></router-view>
  </div>
</template>

<style>
  body, html {
    height: 100%;
  }

  .calendars {
    flex-wrap: nowrap !important;
    font-family: Roboto;
  }

  .vue-daterange-picker > .reportrange-text {
    border: none;
    padding: 0px;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex';
import http from '@/common/http';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'App',

  computed: {
    ...mapState([
      'appName',
    ]),
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      loadingInitialState: (state) => state.auth.loadingInitialState,
      profile: (state) => state.auth.profile,
    }),
  },
  data: () => ({
  }),
  watch: {
    isLoggedIn(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        this.$router.push({ name: 'login' });
      }
    },
  },
  created() {
    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response?.status || 500;
        switch (status) {
          case 401:
          case 403:
            this.logout();
            break;
          default:
            console.error(error);
        }

        return Promise.reject(error);
      },
    );
  },
  methods: {
    ...mapActions(['logout']),
  },
};
</script>
