<template>
  <base-component :component="component" :dashboard="dashboard">
    <template v-slot:content>
      <div v-if="dataValue">
        <p :style="textStyle">
          {{ dataValue }}
        </p>
      </div>
      <v-progress-circular
        v-else indeterminate
        color="primary darken-2"
      ></v-progress-circular>
    </template>
    <template v-slot:footer>
      {{ shortDate(dataTimestamp) }}
    </template>
  </base-component>
</template>

<script>
import mixins from '@/mixins/model-mixins.js';
import dashboardComponentHelpers from '@/mixins/dashboard-component-helpers.js';

export default {
  name: 'SingleValueVomponent',
  mixins: [
    mixins.DataSetMixin,
    dashboardComponentHelpers,
  ],
  computed: {
    configuration() {
      return this.component?.configuration;
    },
    textStyle() {
      const fontSize = `${this.configuration?.fontSize || 30}px`;
      return {
        'font-size': fontSize,
        'line-height': fontSize,
        width: '100%',
        margin: 0,
        'text-align': this.configuration?.textAlign,
        'font-family': this.configuration?.font || 'mono',
        color: this.configuration?.textColor || '#000',
      };
    },
    dataTimestamp() {
      if (this.data?.length) {
        return this.data[0].timestamp;
      }
      return null;
    },
    dataValue() {
      if (this.data?.length) {
        const latest = this.configuration.type === 'Latest';
        const sum = this.configuration.type === 'Sum';
        const min = this.configuration.type === 'Min';
        const max = this.configuration.type === 'Max';
        if (latest) {
          const [, value] = Object.entries(this.data[0]).find(([key]) => key !== 'timestamp');
          return value;
        }
        if (sum) {
          return this.data.reduce((acc, item) => {
            const [, value] = Object.entries(item).find(([key]) => key !== 'timestamp');
            return acc + parseFloat(value);
          }, 0);
        }
        if (min) {
          return Math.min(...this.data.map((item) => {
            const [, value] = Object.entries(item).find(([key]) => key !== 'timestamp');
            return parseFloat(value);
          }));
        }
        if (max) {
          return Math.max(...this.data.map((item) => {
            const [, value] = Object.entries(item).find(([key]) => key !== 'timestamp');
            return parseFloat(value);
          }));
        }
      }
      return null;
    },
  },
  created() {
    if (this.configuration?.dataSetId && (this.dataSet?.id !== this.configuration.dataSetId)) {
      this.getDataSet(this.configuration?.dataSetId).then((set) => { this.dataSet = set; });
    }
  },
  data: () => ({
    dataSet: null,
    data: null,
  }),
  watch: {
    configuration: {
      deep: true,
      async handler(v) {
        if (v && v.dataSetId && (this.dataSet?.id !== v.dataSetId)) {
          this.dataSet = await this.getDataSet(this.dataSet.id);
        }
      },
    },
    async dataSet(v) {
      if (v) {
        const params = {
          id: v.id,
          start: this.start,
          end: this.end,
          limit: this.configuration.type === 'Latest' ? 1 : undefined,
        };

        const data = await this.getDataForDataSet(params);
        this.data = data;
      }
    },
  },
};
</script>
