import http from '@/common/http';
import notifier from '@/common/notifier';

export default {
  state: {
    settings: null,
    savingSettings: false,
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings;
    },
    setLoadingSettings(state, loading) {
      state.loadingSettings = loading;
    },
    setSavingSettings(state, saving) {
      state.savingSettings = saving;
    },
  },
  actions: {
    async loadSettings({ commit }) {
      commit('setLoadingSettings', true);
      try {
        const response = await http.get('/settings/config');
        commit('setSettings', response.data || {});
      } catch (e) {
        console.error(e);
        notifier.notifyError('Failed to load settings.');
      } finally {
        commit('setLoadingSettings', false);
      }
    },
    async saveSettings({ commit }, settings) {
      commit('setSavingSettings', true);
      try {
        await http.put('/settings/config', settings);
        commit('setSettings', settings);
        notifier.notifySuccess('Successfully Saved settings.');
      } catch (e) {
        console.error(e);
        notifier.notifyError('Failed to save settings.');
      } finally {
        commit('setSavingSettings', false);
      }
    },
  },
};
