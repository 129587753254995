<template>
  <base-component :component="component" :dashboard="dashboard">
    <template v-slot:content>
      <div style="height: 100%; width: 100%; position: relative;">
        <div id="map" :style="mapStyle"></div>
      </div>
    </template>
    <!-- <template v-slot:footer v-if="!markers.length">
      Monitoring points have no geo location.
    </template> -->
  </base-component>
</template>

<script>
import modelMixins from '@/mixins/model-mixins.js';
import dashboardComponentHelpers from '@/mixins/dashboard-component-helpers.js';

export default {
  name: 'MapComponent',
  mixins: [
    dashboardComponentHelpers,
    modelMixins.MonitoringPointMixin,
  ],
  async mounted() {
    const google = await this.google;
    this.map = new google.maps.Map(document.getElementById('map'), {
      gestureHandling: 'cooperative',
    });

    const bounds = new google.maps.LatLngBounds();

    await this.loadMonitoringPoints();

    this.markers = this.configuration.monitoringPointIds
      .map((id) => this.monitoringPoints.find((point) => point.id === id))
      .map((mp) => { console.log(mp); return mp; })
      .filter((mp) => mp?.lat && mp?.lng)
      .map((mp) => {
        const toFloat = {
          lat: parseFloat(mp.lat),
          lng: parseFloat(mp.lng),
        };
        bounds.extend(toFloat);
        const marker = new google.maps.Marker({
          position: toFloat,
          title: mp.name,
          map: this.map,
        });

        return marker;
      });

    this.map.fitBounds(bounds);
    this.map.setZoom(16);
  },
  data: () => ({
    map: null,
    markers: [],
  }),
  computed: {
    mapStyle() {
      return {
        height: '100%',
        width: '100%',
      };
    },
  },
};
</script>
