<template>
  <v-data-table
    :headers="[...headers, ...additionalHeaders]"
    :items="tableItems"
    :loading="loading"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat color="primary darken-3" dark>
        <v-toolbar-title>
          <slot name="title"></slot>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          link
          v-if="newLink"
          :to="newLink"
          color="accent"
          small
        >New</v-btn>
      </v-toolbar>
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        width="500"
      >
        <v-card tile>
          <v-toolbar flat dark color="primary darken-3">
            <v-toolbar-title>
              Delete Item
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4">
            {{ dialogText }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="dialog = false"
            >Cancel</v-btn>
            <v-btn
              color="accent"
              dark
              @click="deleteItem"
            >Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.Actions="{ item }">
      <v-btn
        link
        :to="`${$route.path}/${item.id || item.Id}`"
        icon
      >
        <v-icon color="primary">mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="viewRoute"
        link
        :to="`${viewRoute}/${item.id || item.Id}`"
        icon
      >
        <v-icon color="primary">mdi-eye</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="confirmDelete(item)"
        color="accent"
      >
        <v-icon>
          mdi-trash-can
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    headers: Array,
    items: Array,
    loading: Boolean,
    newLink: [Object, String],
    editLink: [Object, String],
    viewRoute: String,
  },
  computed: {
    tableItems() {
      return this.items;
    },
  },
  data: () => ({
    additionalHeaders: [
      {
        text: 'Actions',
        value: 'Actions',
        align: 'right',
        sortable: false,
      },
    ],
    search: '',
    dialog: null,
    dialogText: '',
  }),
  methods: {
    confirmDelete(item) {
      this.toDelete = item;
      this.dialog = true;
      this.dialogText = `Are you sure you want to delete ${item[this.nameProp || 'name']}?`;
    },
    deleteItem() {
      this.$emit('delete', this.toDelete.id || this.toDelete.Id);
      this.toDelete = null;
      this.dialog = false;
    },
  },
};
</script>
