import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

import LoginView from '../views/LoginView.vue';
// import ProjectsView from '../views/ProjectsView.vue';
// import ProjectView from '../views/ProjectView.vue';
// import DashboardView from '../views/DashboardView.vue';

// Data Sets
// import AdminDataSetsView from '../views/admin/AdminDataSetsView.vue';
// import EditDataSetView from '../views/admin/EditDataSetView.vue';

// Dash
// import AdminDashboardsView from '../views/admin/AdminDashboardsView.vue';

import AdminUsersView from '../views/admin/AdminUsersView.vue';
import AdminSettingsView from '../views/admin/AdminSettingsView.vue';
import EditUserView from '../views/admin/EditUserView.vue';
// import AdminReportsView from '../views/admin/AdminReportsView.vue';
// import AdminProjectsView from '../views/admin/AdminProjectsView.vue';
// import EditProjectView from '../views/admin/EditProjectView.vue';
// import AdminGraphsView from '../views/admin/AdminGraphsView.vue';
// import EditGraphView from '../views/admin/EditGraphView.vue';
// import AdminDataImportView from '../views/admin/AdminDataImportView.vue';
// import AdminJobStatusView from '../views/admin/AdminJobStatusView.vue';
// import AdminSensorDataView from '../views/admin/AdminSensorDataView.vue';
// import AdminDataSourcesView from '../views/admin/AdminDataSourcesView.vue';
// import EditDataSourceView from '../views/admin/EditDataSourceView.vue';
import TelegrafView from '../views/admin/TelegrafView.vue';
import FilesView from '../views/admin/FilesView.vue';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Welcome',
    },
  },
  {
    path: '/admin/telegraf',
    name: 'admin:telegraf',
    component: TelegrafView,
    meta: {
      title: 'Admin Telegraf',
    },
  },
  {
    path: '/admin/files',
    name: 'admin:files',
    component: FilesView,
    meta: {
      title: 'Admin Files',
    },
  },
  // DataSets
  // {
  //   path: '/admin/data-sets',
  //   name: 'admin:datasets',
  //   component: AdminDataSetsView,
  //   meta: {
  //     title: 'Data Sets',
  //   },
  // },
  // {
  //   path: '/admin/data-sets/new',
  //   name: 'admin:datasets:create',
  //   component: EditDataSetView,
  //   meta: {
  //     title: 'Create Data Set',
  //     create: true,
  //   },
  // },
  // {
  //   path: '/admin/data-sets/:id',
  //   name: 'admin:datasets:edit',
  //   component: EditDataSetView,
  //   meta: {
  //     title: 'Edit Data Set',
  //   },
  // },
  // Dashboards
  // {
  //   path: '/dashboard/:id',
  //   name: 'dashboard:view',
  //   component: DashboardView,
  //   meta: {
  //     title: 'View Dashboard',
  //   },
  // },
  // {
  //   path: '/admin/dashboard/new',
  //   name: 'admin:dashboard:create',
  //   component: DashboardView,
  //   meta: {
  //     title: 'Create Dashboard',
  //     create: true,
  //   },
  // },
  // {
  //   path: '/admin/dashboard/:id',
  //   name: 'admin:dashboard:edit',
  //   component: DashboardView,
  //   meta: {
  //     title: 'Edit Dashboard',
  //     edit: true,
  //   },
  // },
  // {
  //   path: '/admin/dashboard',
  //   name: 'admin:dashboards',
  //   component: AdminDashboardsView,
  //   meta: {
  //     title: 'Dashboard Admin',
  //   },
  // },
  // Data Sources
  // {
  //   path: '/admin/data-sources/new',
  //   name: 'admin:dataSources:create',
  //   component: EditDataSourceView,
  //   meta: {
  //     title: 'Create Data Source',
  //     create: true,
  //   },
  // },
  // {
  //   path: '/admin/data-sources/:id',
  //   name: 'admin:dataSources:edit',
  //   component: EditDataSourceView,
  //   meta: {
  //     title: 'Edit Data Source',
  //     create: false,
  //   },
  // },
  // {
  //   path: '/admin/data-sources',
  //   name: 'admin:datasources',
  //   component: AdminDataSourcesView,
  //   meta: {
  //     title: 'Data Sources',
  //   },
  // },
  // Login
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Login',
    },
  },
  // Projects
  // {
  //   path: '/projects',
  //   name: 'projects',
  //   component: ProjectsView,
  //   meta: {
  //     title: 'My Projects',
  //   },
  // },
  // {
  //   path: '/projects/:id',
  //   name: 'project',
  //   component: ProjectView,
  //   meta: {
  //     title: 'View Project',
  //   },
  // },
  // {
  //   path: '/admin/projects',
  //   name: 'admin:projects',
  //   component: AdminProjectsView,
  //   meta: {
  //     title: 'Project Admin',
  //   },
  // },
  // {
  //   path: '/admin/projects/new',
  //   name: 'admin:projects:create',
  //   component: EditProjectView,
  //   meta: {
  //     title: 'Create project',
  //   },
  // },
  // {
  //   path: '/admin/projects/:id',
  //   name: 'admin:projects:edit',
  //   component: EditProjectView,
  //   meta: {
  //     title: 'Edit Project',
  //   },
  // },
  // Users
  {
    path: '/admin/users',
    name: 'admin:users',
    component: AdminUsersView,
    meta: {
      title: 'User Admin',
    },
  },
  {
    path: '/admin/users/new',
    name: 'admin:users:create',
    component: EditUserView,
    meta: {
      title: 'Create User',
    },
  },
  {
    path: '/admin/users/:id',
    name: 'admin:users:edit',
    component: EditUserView,
    meta: {
      title: 'Edit User',
    },
  },
  // Settings
  {
    path: '/admin/settings',
    name: 'admin:settings',
    component: AdminSettingsView,
    meta: {
      title: 'App Settings',
    },
  },
  // // Reports
  // {
  //   path: '/admin/reports',
  //   name: 'admin:reports',
  //   component: AdminReportsView,
  //   meta: {
  //     title: 'Report Admin',
  //   },
  // },
  // // Graphs
  // {
  //   path: '/admin/graphs',
  //   name: 'admin:graphs',
  //   component: AdminGraphsView,
  //   meta: {
  //     title: 'Graph Admin',
  //   },
  // },
  // {
  //   path: '/admin/graphs/new',
  //   name: 'admin:graphs:create',
  //   component: EditGraphView,
  //   meta: {
  //     title: 'Create Graph',
  //     create: true,
  //   },
  // },
  // {
  //   path: '/admin/graphs/:id',
  //   name: 'admin:graphs:edit',
  //   component: EditGraphView,
  //   meta: {
  //     title: 'Edit Graph',
  //   },
  // },
  // // Import
  // {
  //   path: '/admin/import',
  //   name: 'admin:import',
  //   component: AdminDataImportView,
  //   meta: {
  //     title: 'Data Import',
  //   },
  // },
  // // Jobs
  // {
  //   path: '/admin/jobs',
  //   name: 'admin:jobs',
  //   component: AdminJobStatusView,
  //   meta: {
  //     title: 'Job Status',
  //   },
  // },
  // // Admin Sensor Data
  // {
  //   path: '/admin/view-data',
  //   name: 'admin:viewdata',
  //   component: AdminSensorDataView,
  //   meta: {
  //     title: 'View Data',
  //   },
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.document.title = `${to.meta && to.meta.title ? to.meta.title : 'Home'} - DataGlance`;

  const profile = await store.dispatch('getProfile');

  if (!profile && to.name !== 'login') {
    localStorage.next_route = to.name || 'home';
    next({ name: 'login' });
  } else {
    if (to.path.startsWith('/admin') && profile.role?.name !== 'Admin') {
      next({ name: 'home' });
    }
    next();
  }
});

export default router;
