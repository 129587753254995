import http from '@/common/http';
import notifier from '@/common/notifier';

const base = '/v1/data-sources';

export default {
  state: {
    dataSourceData: [],
    dataSourceDataIds: {},
    loadingDataSourceData: false,
    loadingDataSourceDataIds: false,
  },
  mutations: {
    setDataSourceData(state, data) {
      state.dataSourceData = data;
    },
    setDataSourceDataIds(state, data) {
      state.dataSourceDataIds = data;
    },
    setLoadingDataSourceData(state, loading) {
      state.loadingDataSourceData = loading;
    },
    setLoadingDataSourceDataIds(state, loading) {
      state.loadingDataSourceDataIds = loading;
    },
  },
  actions: {
    async getDataSourceData(
      { commit },
      {
        sourceId, dataId, start = Date.now() - 3600, end = Date.now(),
      },
    ) {
      if (!sourceId || !dataId) {
        console.error('Cannot search for data source data without source and data id');
        return null;
      }

      commit('setLoadingDataSourceData', true);
      commit('setDataSourceData', null);

      try {
        const response = await http.get(
          `${base}/${sourceId}/data/${dataId}`,
          { params: { start, end } },
        );
        commit('setDataSourceData', response.data);

        return response.data;
      } catch (e) {
        console.error(e);
        notifier.notifyError('Failed to load data source data.');
      } finally {
        commit('setLoadingDataSourceData', false);
      }

      return null;
    },
    async loadDataSourceDataIds({ commit, state }, sourceId) {
      try {
        commit('setLoadingDataSourceDataIds', true);
        const result = await http.get(`${base}/${sourceId}/data-ids`);
        commit('setDataSourceDataIds', {
          ...state.dataSourceDataIds,
          [sourceId]: result.data,
        });
      } catch (e) {
        console.error(e);
        notifier.notifyError('Failed to load data IDs.');
      } finally {
        commit('setLoadingDataSourceDataIds', false);
      }
    },
  },
};
