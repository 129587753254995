<template>
  <v-app>
    <v-main class="primary darken-2" app>
      <v-container
        fluid
        fill-height
      >
        <v-row justify="center">
          <v-col cols="12" sm="8" md="6" lg="4">
            <v-form ref="form">
              <v-card class="elevation-12" tile>
                <v-toolbar dark flat color="primary darken-3">
                  <v-toolbar-title>
                    {{ didForgetPassword ? 'Reset Password' : 'Login' }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    prepend-icon="mdi-account"
                    name="login"
                    label="Email"
                    type="text"
                    v-model="email"
                    :rules="emailRules"
                    v-on:blur="setEmailRules"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    type="password"
                    v-model="password"
                    v-if="!didForgetPassword"
                    :rules="[dgr.required, dgr.minLength(8)]"
                    v-on:keyup.enter="doLogin"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="primary darken-2"
                    @click.prevent="didForgetPassword = true"
                    v-if="!didForgetPassword"
                  >Forgot Password</v-btn>
                  <v-btn
                    color="primary darken-2"
                    @click.prevent="didForgetPassword = false"
                    v-if="didForgetPassword"
                  >Back</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="accent"
                    :loading="loadingLogin"
                    v-if="!didForgetPassword"
                    @click="doLogin"
                  >Login</v-btn>
                  <v-btn
                    color="accent"
                    :loading="sendingForgotEmail"
                    v-if="didForgetPassword"
                    @click="doLogin"
                  >Send Reset Email</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data: () => ({
    email: '',
    password: '',
    didForgetPassword: false,
    sendingForgotEmail: false,
    emailRules: [],
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      loadingInitialState: (state) => state.auth.loadingInitialState,
      loginErrors: (state) => state.auth.loginErrors,
      loadingLogin: (state) => state.auth.loadingLogin,
    }),
  },
  methods: {
    ...mapActions([
      'login',
      'forgotPassword',
    ]),
    setEmailRules() {
      this.emailRules = [this.dgr.email, this.dgr.required];
    },
    async doLogin() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.didForgetPassword) {
        try {
          this.sendingForgotEmail = true;
          await this.forgotPassword({ email: this.email });
          this.notifySuccess('Successfully sent password reset email.');
        } catch (e) {
          console.error(e);
          this.notifyError('Failed to send password reset email.');
        } finally {
          this.sendingForgotEmail = false;
        }
      } else {
        try {
          await this.login({ email: this.email, password: this.password });
          if (this.isLoggedIn) {
            this.$router.push({ name: localStorage.next_route || 'home' });
          }
        } catch (e) {
          console.error(e);
          this.notifyError('Failed to login.');
        }
      }
    },
  },
};
</script>
