import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const colors = [
  '#544d6d', // Dark lilac
  '#1d3e59', // Marine blue
  '#254669', // Downpour blue
  '#646769', // charcoal slate
  '#4c4f52', // french beret
  '#6d7e89', // Normandy
  '#2b3b4c', // North Sea
  '#6f8f8e', // Fort Pierce Green
  '#304656', // Washington Blue
];

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors[5],
        secondary: '#4B4D4F',
        accent: '#762C23',
        error: '#b71c1c',
      },
    },
  },
});
