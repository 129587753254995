<template>
  <v-card tile>
    <v-toolbar flat dark color="primary darken-3">
      <v-toolbar-title style="width: 100%" class="d-flex justify-space-between">
        {{ title }}
        <v-icon color="white">mdi-information</v-icon>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AboutComponent',
  props: {
    title: String,
  },
};
</script>
