<template>
  <v-autocomplete
    v-model="id"
    :disabled="loadingProjects"
    :items="displayMonitoringPoints"
    filled
    chips
    color="blue-grey lighten-2"
    label="Monitoring Points"
    item-text="name"
    item-value="id"
    :multiple="multiple"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        color="primary darken-1"
        @click="data.select"
        @click:close="remove(data.item)"
      >
        {{ data.item.name }} ({{ data.item.shortTarget }})
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
          <v-list-item-subtitle v-if="data.item.project.name" v-html="data.item.project.name">
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import mixins from '@/mixins/index.js';

export default {
  mixins: [mixins.ProjectMixin],
  name: 'MonitoringPointAutocompleteComponent',
  props: {
    value: {
      type: [Array, String],
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    displayMonitoringPoints() {
      return this.projects?.flatMap((project) => project.monitoringPoints?.map((mp) => ({
        ...mp,
        project,
      })) || []);
    },
  },
  data: () => ({
    id: null,
  }),
  created() {
    if (!this.projects.length && !this.loadingProjects) {
      this.loadProjects();
    }
    this.id = this.value;
  },
  methods: {
    remove(item) {
      const i = this.id.indexOf(item.id);
      if (i > -1) {
        this.id.splice(i, 1);
      }
    },
  },
  watch: {
    id(v) {
      this.$emit('input', v);
    },
  },
};
</script>
