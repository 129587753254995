import http from '@/common/http';
import notifier from '@/common/notifier';

export default {
  state: {
    devices: [],
    loadingDevices: false,
  },
  mutations: {
    setDevices(state, devices) {
      state.devices = devices;
    },
    setLoadingDevices(state, loading) {
      state.loadingDevices = loading;
    },
  },
  actions: {
    async loadDevices({ commit }) {
      try {
        commit('setLoadingDevices', true);
        const response = await http.get('/devices');
        commit('setDevices', response.data);
      } catch (e) {
        console.error(e);
        notifier.notifyError('Failed to load sigicom devices.');
      } finally {
        commit('setLoadingDevices', false);
      }
    },
  },
};
