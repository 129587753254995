import ImageComponent from '../components/dashboard/component/ImageComponent.vue';
import GraphComponent from '../components/dashboard/component/GraphComponent.vue';
import TextComponent from '../components/dashboard/component/TextComponent.vue';
import SingleValueComponent from '../components/dashboard/component/SingleValueComponent.vue';
import MapComponent from '../components/dashboard/component/MapComponent.vue';

const mapping = {
  image: ImageComponent,
  graph: GraphComponent,
  text: TextComponent,
  single: SingleValueComponent,
  map: MapComponent,
};

export default {
  components: {
    ImageComponent,
    GraphComponent,
    SingleValueComponent,
    TextComponent,
    MapComponent,
  },
  methods: {
    getComponentForType(type) {
      return mapping[type];
    },
  },
};
