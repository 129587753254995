import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      profile: (state) => state.auth.profile,
    }),
  },
};
