<template>
  <base-component :component="component" :dashboard="dashboard">
    <template v-slot:content>
      <p :style="textStyle">
        <pre :style="preStyle" style="white-space: pre-wrap;">{{ configuration.text }}</pre>
      </p>
    </template>
  </base-component>
</template>

<script>
import dashboardComponentHelpers from '@/mixins/dashboard-component-helpers.js';

export default {
  name: 'TextComponent',
  mixins: [
    dashboardComponentHelpers,
  ],
  computed: {
    configuration() {
      return this.component.configuration;
    },
    preStyle() {
      return {
        'font-family': this.configuration?.font || 'mono',
      };
    },
    textStyle() {
      const fontSize = `${this.configuration?.fontSize || 30}px`;
      return {
        'font-size': fontSize,
        'line-height': fontSize,
        width: '100%',
        margin: 0,
        'text-align': this.configuration?.textAlign,
        color: this.configuration?.textColor || this.dashboard.configuration.textColor || '#000',
      };
    },
  },
};
</script>
