<template>
  <codemirror v-model="code" :options="cmOption" />
</template>

<style>
  .CodeMirror {
    height: auto;
  }
</style>

<script>
import { codemirror } from 'vue-codemirror';
import 'codemirror/keymap/sublime';
import 'codemirror/lib/codemirror.css';
import 'codemirror/lib/codemirror';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/darcula.css';

export default {
  props: {
    value: String,
    mode: {
      type: [String, Object],
      required: false,
    },
  },
  components: {
    codemirror,
  },
  created() {
    this.code = this.value;
    if (this.mode) {
      this.cmOption.mode = this.mode;
    }
  },
  computed: {
    composedOptions() {
      return {
        ...this.cmOption,
        mode: this.mode,
      };
    },
  },
  data() {
    return {
      code: '',
      cmOption: {
        height: '50vh',
        code: '',
        tabSize: 2,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        foldGutter: true,
        styleSelectedText: true,
        mode: { name: 'handlebars', base: 'text/html' },
        keyMap: 'sublime',
        matchBrackets: true,
        showCursorWhenSelecting: true,
        theme: 'darcula',
        extraKeys: { Ctrl: 'autocomplete' },
        hintOptions: {
          completeSingle: false,
        },
        // lint: true,
        // gutters: ['CodeMirror-lint-markers'],
      },
    };
  },
  watch: {
    code(v) {
      this.$emit('input', v);
    },
  },
};
</script>
