import Vue from 'vue';

export default {
  notifySuccess(text, title, group = 'default') {
    Vue.notify({
      group,
      title,
      text,
      type: 'success',
    });
  },
  notifyError(text, title, group = 'default') {
    Vue.notify({
      group,
      title,
      text,
      type: 'error',
    });
  },
};
