<template>
  <file-browser
    :axios="http"
    :storages="storages"
    :endpoints="endpoints"
  />
</template>

<script>
import http from '@/common/http';
import FileBrowser from 'vuetify-file-browser';

export default {
  data: () => ({
    http,
    storage: 'archive',
    endpoints: {
      list: { url: '/v1/file-browser/{storage}/list?path={path}' },
      upload: { url: '/v1/file-browser/{storage}/upload?path={path}' },
      mkdir: { url: '/v1/file-browser/{storage}/mkdir?path={path}' },
      delete: { url: '/v1/file-browser/{storage}/delete?path={path}' },
    },
  }),
  components: {
    FileBrowser,
  },
};
</script>
