<template>
  <v-menu
    v-model="colorPickerMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    origin="center center"
    offset-x
    left
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div>
        <span v-if="label">{{ label }}</span>
          <v-btn
            readonly
            v-bind="attrs"
            v-on="on"
            icon
            :elevation="2"
          >
            <v-icon :color="color">mdi-circle</v-icon>
          </v-btn>
      </div>
    </template>
    <v-color-picker
      class="dg-color-picker"
      label="Text Color"
      dot-size="25"
      swatches-max-height="200"
      v-model="color"
    ></v-color-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'SimpleColorPicker',
  props: ['value', 'label'],
  data: () => ({
    color: {},
    colorPickerMenu: false,
  }),
  created() {
    this.color = this.value || '#000';
  },
  watch: {
    color: {
      handler(v) {
        if (v) {
          this.$emit('input', typeof v === 'string' ? v : v.hexa);
        }
      },
      deep: true,
    },
  },
};
</script>
