<template>
  <v-autocomplete
    label="Data Source"
    :items="dataSources"
    v-model="selectedSource"
    return-object
    @change="$emit('input', selectedSource)"
    :loading="loadingDataSources"
    item-text="name"
    clearable
    append-outer-icon="mdi-refresh"
    @click:append-outer="loadDataSources"
  ></v-autocomplete>
</template>

<script>
import mixins from '@/mixins/model-mixins.js';

export default {
  name: 'DataSourceSelect',
  mixins: [mixins.DataSourceMixin],
  data: () => ({
    selectedSource: null,
  }),
  mounted() {
    this.selectedSource = this.value;
    if (!this.dataSources.length && !this.loadingDataSources) {
      this.loadDataSources();
    }
  },
  props: {
    value: Object,
  },
  watch: {
    value(v) {
      this.selectedSource = v;
    },
  },
};
</script>
