import { startCase, kebabCase, camelCase } from 'lodash';

export const GOOGLE_API_KEY = 'AIzaSyCiVpylJBJfcY48VRQ7mlUY5HhAylFXhDs';

// Used to generate Vuex store and mixins.
export const models = [
  'data set',
  'data source',
  'dashboard',
  'graph',
  'monitoring point',
  'project',
  'user',
].map((model) => {
  const asCamel = camelCase(model);
  const pascalCase = startCase(asCamel).replace(/ /g, '');
  return {
    pascalCase,
    name: model,
    api: `${kebabCase(model)}s`,
    // State names
    modelStateName: `${asCamel}s`,
    loadingStateName: `loading${pascalCase}s`,
    creatingStateName: `creating${pascalCase}`,
    updatingStateName: `updating${pascalCase}`,
    deletingStateName: `deleting${pascalCase}`,
    gettingStateName: `getting${pascalCase}`,
    // Mutator names
    modelMutatorName: `set${pascalCase}s`,
    loadingMutatorName: `setLoading${pascalCase}`,
    creatingMutatorName: `setCreating${pascalCase}`,
    updatingMutatorName: `setUpdating${pascalCase}`,
    deletingMutatorName: `setDeleting${pascalCase}`,
    gettingMutatorName: `setGetting${pascalCase}`,
    // Action names
    loadActionName: `load${pascalCase}s`,
    getActionName: `get${pascalCase}`,
    createActionName: `create${pascalCase}`,
    updateActionName: `update${pascalCase}`,
    deleteActionName: `delete${pascalCase}`,
    // Getters
    getByIdName: `get${pascalCase}ById`,
    getByName: `get${pascalCase}By`,
  };
});
