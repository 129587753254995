<template>
  <v-combobox
    label="Data ID"
    :items="dataIds"
    v-model="dataId"
    :loading="loadingDataSourceDataIds"
    clearable
    append-outer-icon="mdi-refresh"
    @click:append-outer="loadDataSourceDataIds(dataSourceId)"
  ></v-combobox>
</template>

<script>

export default {
  name: 'DataSourceDataIdCombobox',
  props: {
    dataSourceId: String,
    value: String,
  },
  data: () => ({
    dataId: null,
  }),
  created() {
    if (this.dataSourceId) {
      this.loadDataSourceDataIds(this.dataSourceId);
    }
    this.dataId = this.value;
  },
  computed: {
    dataIds() {
      return this.dataSourceDataIds[this.dataSourceId]?.map((id) => id.id).sort() || [];
    },
  },
  watch: {
    dataSourceId(v) {
      if (v) {
        this.loadDataSourceDataIds(v);
        this.dataId = '';
      }
    },
    dataId() {
      this.$emit('input', this.dataId);
    },
  },
};
</script>
