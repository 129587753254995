import {
  Chart,
  registerables,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(...registerables);
Chart.register(zoomPlugin);
Chart.register(annotationPlugin);
Chart.defaults.color = '#666';
Chart.defaults.borderColor = '#666';
Chart.defaults.font.family = 'Roboto';
Chart.defaults.plugins.legend.labels.color = '#666';
