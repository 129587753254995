<template>
  <page-component>
    <template v-slot:header>
      Files
    </template>
    <template v-slot:content>
      <v-row>
        <v-col>
          <file-browser></file-browser>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <about-component title="Files">
            These are your files. Telegraf has access to these files.
            You can manage them here (soon) or through the FTP.
            <p>
              When you upload a file via the FTP server, the file is added to
              an archive of all your files.
            </p>
          </about-component>
        </v-col>
      </v-row>
    </template>
  </page-component>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue';
import FileBrowser from '@/components/common/FileBrowser.vue';
import http from '@/common/http';

export default {
  name: 'FilesView',
  components: {
    PageComponent,
    FileBrowser,
  },
  created() {
    http.get('/v1/telegraf/files')
      .then((res) => {
        console.log(res.data);
        this.files = [res.data];
      });
  },
  data: () => ({
    config: '',
    log: '',
    saving: false,
    logInterval: null,
    filesIcons: {
      json: 'mdi-code-json',
      txt: 'mdi-file-document-outline',
      csv: 'mdi-table',
    },
    files: [],
    tree: [],
  }),
  methods: {},
};
</script>
