<template>
  <v-app>
    <v-app-bar
      app
      dark
      flat
      class="primary darken-3"
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <slot name="app-bar-left"></slot>

      <v-spacer></v-spacer>

      <span class="text-uppercase">Data</span>
      <span class="text-uppercase primary--text text--lighten-2">Glance</span>

      <v-spacer></v-spacer>

      <slot name="app-bar-right"></slot>
    </v-app-bar>
    <v-navigation-drawer
      v-if="isLoggedIn"
      v-model="drawer"
      app
      dark
      class="primary darken-3"
    >
      <v-list>
        <v-list-item class="px-2 justify-center">
          <v-list-item-avatar>
            <v-img :src="profile.photo || defaultPhoto"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link class="text-center">
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ profile.firstName }} {{ profile.lastName }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ profile.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-btn
              v-if="isLoggedIn"
              color="primary darken-2"
              @click="logout"
              elevation="0"
            >Logout</v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
        dense
      >
        <v-list-group
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Projects</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(project, i) in sortedProjects"
            :key="`project-nav-link-${i}`"
            link
            :to="{ name: 'project', params: { id: project.id } }"
          >
            <v-list-item-title
              v-text="project.name"
            ></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          no-action
          v-if="sortedDashboards.length"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Dashboards</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(dashboard, i) in sortedDashboards"
            :key="`dashboard-nav-link-${i}`"
            link
            :to="{ name: 'dashboard:view', params: { id: dashboard.id } }"
          >
            <v-list-item-title
              v-text="dashboard.name"
            ></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>

        <v-subheader>Admin</v-subheader>

        <v-list-item
          v-for="([title, icon, to], i) in admins"
          :key="i"
          link
          :to="to"
        >
          <v-list-item-icon>
            <v-icon v-text="icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="title"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid class="primary darken-2">
        <v-card flat tile class="primary darken-1">
          <v-toolbar flat tile dark class="primary darken-3">
            <v-toolbar-title>
              <h2><slot name="header">
                {{ $route.meta && $route.meta.title }}
              </slot></h2>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <slot name="actions"></slot>
          </v-toolbar>
            <v-divider></v-divider>
          <v-card-text style="overflow-y: auto; height: calc(100vh - 154px);" ref="contentbox">
            <slot name="content">
              <div style="width: 100%; height: 100%;" class="d-flex justify-center align-center">
                <v-progress-circular
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </div>
            </slot>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
  html, body {
    overflow: hidden;
  }

  .footer {
    position: fixed;
    bottom: 0;
  }

  .header .v-btn {
    margin: 0.5em;
  }
</style>

<script>
import goTo from 'vuetify/lib/services/goto';
import { mapState, mapActions } from 'vuex';
import mixins from '@/mixins/index.js';

export default {
  mixins: [mixins.ProjectMixin, mixins.DashboardMixin, mixins.AuthMixin],
  data: () => ({
    defaultPhoto: '/img/default-profile.png',
    admins: [
      ['Users', 'mdi-account-multiple', { name: 'admin:users' }],
      // ['Projects', 'mdi-folder-cog', { name: 'admin:projects' }],
      // ['Settings', 'mdi-cog', { name: 'admin:settings' }],
      // ['Reports', 'mdi-file-pdf-box', { name: 'admin:reports' }],
      // // ['Import Data', 'mdi-table-arrow-left', { name: 'admin:import' }],
      // ['Job Status', 'mdi-autorenew', { name: 'admin:jobs' }],
      // ['Graphs', 'mdi-chart-bar', { name: 'admin:graphs' }],
      // ['View Data', 'mdi-table', { name: 'admin:viewdata' }],
      // ['Dashboards', 'mdi-view-dashboard-edit', { name: 'admin:dashboards' }],
      // ['Data Sources', 'mdi-database-arrow-left', { name: 'admin:datasources' }],
      ['Telegraf', 'mdi-cog', { name: 'admin:telegraf' }],
      ['Files', 'mdi-folder', { name: 'admin:files' }],
    ],
    drawer: null,
    rightDrawer: false,
  }),
  computed: {
    ...mapState([
      'appName',
    ]),
    sortedProjects() {
      return (this.projects || []).sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    sortedDashboards() {
      return (this.dashboards || []).sort((a, b) => (a.name > b.name ? 1 : -1));
    },
  },
  created() {
    this.loadProjects();
    // this.loadDashboards();
  },
  mounted() {
    this.$root.scroll = this.scrollTo;
  },
  methods: {
    ...mapActions([
      'logout',
    ]),
    scrollTo(target) {
      goTo(target, { container: this.$refs.contentbox });
    },
  },
};
</script>
