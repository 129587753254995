export default {
  methods: {
    async graphToChartConfiguration(graph, start, end) {
      const datasets = [];

      if (graph.dataSets?.length) {
        const data = await Promise.all(
          graph.dataSets.map((set) => this.dataSetToChartDataset(set, start, end)),
        );
        datasets.push(...data);
      }

      return {
        ...graph.configuration,
        data: {
          ...graph.data,
          datasets,
        },
      };
    },
    async dataSetToChartDataset(dataset, start, end) {
      const setOpts = dataset.options || {};

      if (dataset.dataType === 'Static') { return setOpts; }

      const params = { id: dataset.id, start, end };
      const data = await this.getDataForDataSet(params);
      return { ...setOpts, data };
    },
  },
};
