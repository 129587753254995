import moment from 'moment';
import BaseComponent from '../components/dashboard/component/BaseComponent.vue';

export default {
  props: {
    component: {
      type: Object,
      required: true,
    },
    dashboard: {
      type: Object,
      required: true,
    },
  },
  components: {
    BaseComponent,
  },
  computed: {
    configuration() {
      return this.component?.configuration;
    },
    start() {
      let range = this.dashboard?.configuration?.range;
      if (!range) {
        range = {
          units: 'days',
          count: 3,
        };
      }
      return moment(Date.now()).subtract(range.count, range.units).valueOf();
    },
    end() {
      return Date.now();
    },
  },
};
