<template>
  <v-card
    flat
    tile
    :style="componentStyle"
  >
    <v-card-title
      v-if="component.title"
      :class="titleClass"
      :title="component.title"
    >{{ component.title }}</v-card-title>
    <v-card-subtitle
      v-if="component.subtitle"
      :class="titleClass"
      :title="component.subtitle"
      class="d-flex"
    >{{ component.subtitle }}</v-card-subtitle>
    <v-card-text :style="cardTextStyle" class="d-flex justify-center align-center">
      <slot name="content"></slot>
    </v-card-text>
    <v-subheader
      v-if="$slots.footer"
      :class="titleClass"
    >
      <slot name="footer"></slot>
    </v-subheader>
  </v-card>
</template>

<script>
export default {
  name: 'BaseComponent',
  props: {
    component: Object,
    dashboard: Object,
  },
  computed: {
    componentHeight() {
      if (!this.dc) {
        return 0;
      }
      const { gutterSize, rowHeight } = this.dc;
      const v = ((this.component.height - 1) * gutterSize)
      + (this.component.height * rowHeight) - gutterSize;
      return v;
    },
    dc() {
      return this.dashboard?.configuration;
    },
    componentStyle() {
      const style = {
        height: `${this.componentHeight}px`,
        overflow: 'hidden',
      };

      return style;
    },
    titleClass() {
      const classes = {};
      const align = this.component?.titleAlign || this.dc?.titleAlign || 'start';
      classes[`justify-${align}`] = true;
      classes['text-truncate'] = true;
      return classes;
    },
    cardTextStyle() {
      return {
        height: `${this.componentHeight - (this.component?.title ? 32 : 0) - (this.component?.subtitle ? 22 : 0) - (this.component?.title || this.component?.subtitle ? 32 : 0) - (this.$slots.footer?.length ? 40 : 0)}px`,
        overflow: 'hidden',
      };
    },
  },
};
</script>
