<template>
  <page-component>
    <template v-slot:actions>
      <div>
        <v-btn
          small
          @click="save"
          color="accent"
          :loading="savingSettings"
        >Save</v-btn>
      </div>
    </template>
    <template v-slot:content>
      <v-card tile class="mb-4">
        <v-card-title>Style</v-card-title>
        <v-card-text>
          <div class="d-flex justify-space-between">
            <v-text-field
              style="width: 100px"
              label="Primary Color"
              v-model="$vuetify.theme.themes.light.primary"
            ></v-text-field>
            <v-text-field
              style="width: 100px"
              label="Secondary Color"
              v-model="$vuetify.theme.themes.light.secondary"
            ></v-text-field>
            <v-text-field
              style="width: 100px"
              label="Accent"
              v-model="$vuetify.theme.themes.light.accent"
            ></v-text-field>
          </div>
        </v-card-text>
      </v-card>
      <v-card flat tile>
        <v-card-title>Colors</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="4">
              <v-select
                label="Primary Color"
                :items="primaryColors"
                @change="updateColor"
                item-value="hex"
              >
                <template v-slot:selection="data">
                  <template>
                    <v-list-item-avatar>
                      <v-icon :color="data.item.hex">mdi-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.text"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar>
                      <v-icon :color="data.item.hex">mdi-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.text"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" lg="4">
              <v-select
                label="Secondary Color"
                :items="secondaryColors"
                @change="updateSecondary"
                item-value="hex"
              >
                <template v-slot:selection="data">
                  <template>
                    <v-list-item-avatar>
                      <v-icon :color="data.item.hex">mdi-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.text"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar>
                      <v-icon :color="data.item.hex">mdi-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.text"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" lg="4">
              <v-autocomplete
                label="Accent Color"
                :items="accentColors"
                v-model="$vuetify.theme.themes.light.accent"
                @change="updateAccent"
                item-value="hex"
              >
                <template v-slot:selection="data">
                  <template>
                    <v-list-item-avatar>
                      <v-icon :color="data.item.hex">mdi-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.text"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar>
                      <v-icon :color="data.item.hex">mdi-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.text"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </page-component>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue';

export default {
  name: 'AdminSettingsView',
  components: {
    PageComponent,
  },
  computed: {},
  async mounted() {
    // await this.loadSettings();
  },
  data: () => ({
    savingSettings: false,
    primaryColors: [
      { text: 'Dark Lilac', hex: '#544d6d' },
      { text: 'Marine Blue', hex: '#1d3e59' },
      { text: 'Downpour Blue', hex: '#254669' },
      { text: 'Charcoal Slate', hex: '#646769' },
      { text: 'French Beret', hex: '#4c4f52' },
      { text: 'Normandy', hex: '#6d7e89' },
      { text: 'North Sea', hex: '#2b3b4c' },
      { text: 'Fort Pierce Green', hex: '#6f8f8e' },
      { text: 'Washington Blue', hex: '#304656' },
    ],
    secondaryColors: [
      { text: 'Dark Lilac', hex: '#544d6d' },
      { text: 'Marine Blue', hex: '#1d3e59' },
      { text: 'Downpour Blue', hex: '#254669' },
      { text: 'Charcoal Slate', hex: '#646769' },
      { text: 'French Beret', hex: '#4c4f52' },
      { text: 'Normandy', hex: '#6d7e89' },
      { text: 'North Sea', hex: '#2b3b4c' },
      { text: 'Fort Pierce Green', hex: '#6f8f8e' },
      { text: 'Washington Blue', hex: '#304656' },
    ],
    accentColors: [
      { text: 'Dark Lilac', hex: '#544d6d' },
      { text: 'Marine Blue', hex: '#1d3e59' },
      { text: 'Downpour Blue', hex: '#254669' },
      { text: 'Charcoal Slate', hex: '#646769' },
      { text: 'French Beret', hex: '#4c4f52' },
      { text: 'Normandy', hex: '#6d7e89' },
      { text: 'North Sea', hex: '#2b3b4c' },
      { text: 'Fort Pierce Green', hex: '#6f8f8e' },
      { text: 'Washington Blue', hex: '#304656' },
    ],
  }),
  methods: {
    updateColor(v) {
      this.$vuetify.theme.themes.light.primary = v;
    },
    updateSecondary(v) {
      this.$vuetify.theme.themes.light.secondary = v;
    },
    updateAccent(v) {
      this.$vuetify.theme.themes.light.accent = v;
    },
    save() {
      this.savingSettings = true;
      try {
        console.log('Saving settings.');
        this.notifySuccess('Successfully saved settings.');
      } catch (e) {
        this.notifyError('Failed to save settings.');
      } finally {
        this.savingSettings = false;
      }
    },
  },
};
</script>
