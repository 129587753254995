<template>
  <page-component>
    <template v-slot:header>
      {{ pageTitle }}
    </template>
    <template v-slot:content>
      <v-form v-if="user">
        <v-card flat>
          <v-card-title>
            {{ editUser.email }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editUser.email"
                  label="Email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  label="Status"
                  :items="statuses"
                  item-text="name"
                  item-value="id"
                  v-model="editUser.status"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="editUser.firstName"
                  label="First Name"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="editUser.lastName"
                  label="Last Name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :disabled="!userChanged"
              color="primary"
              @click="save"
              :loading="creating || updating"
            >Save</v-btn>
            <v-btn @click="resetPasswordDialog = true;">Reset password</v-btn>
            <v-btn link :to="{ name: 'admin:users' }">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-card flat class="mt-4">
        <v-card-title>
          Projects
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="projects"
            :headers="projectsTableHeaders"
            :loading="loadingProjects"
          ></v-data-table>
        </v-card-text>
      </v-card>
      <v-dialog
        max-width="500px"
        v-model="resetPasswordDialog"
      >
        <v-card>
          <v-toolbar dark flat>
            <v-toolbar-title>Reset Password</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col>
                  <h3>This will permanently reset the user's password.</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="New Password"
                    type="password"
                    v-model="newPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="resetPasswordDialog = false;">Cancel</v-btn>
            <v-btn color="primary" :loading="resettingPassword" @click="reset">Reset</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </page-component>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue';
import { mapActions, mapState } from 'vuex';
import http from '@/common/http';

export default {
  name: 'EditUserView',
  components: {
    PageComponent,
  },
  computed: {
    ...mapState({
      user: (state) => state.users.user,
      loading: (state) => state.users.loadingUser,
      creating: (state) => state.users.creatingUser,
      updating: (state) => state.users.updatingUser,
    }),
    pageTitle() {
      return this.$route.name === 'admin:users:create'
        ? 'Create User' : 'Edit User';
    },
    userChanged() {
      if (this.loading) return false;

      return this.originalUser !== JSON.stringify(this.editUser);
    },
  },
  async mounted() {
    this.loadRoles();
    this.loadingProjects = true;
    http.get(`/users/${this.$route.params.id}/projects`)
      .then((response) => {
        this.projects = response.data;
      })
      .finally(() => {
        this.loadingProjects = false;
      });
    if (this.$route.name === 'admin:users:edit') {
      const originalUser = await this.getUser(this.$route.params.id);
      this.originalUser = JSON.stringify(originalUser);
      this.editUser = JSON.parse(this.originalUser);
    } else {
      this.originalUser = JSON.stringify(this.emptyUser);
      this.editUser = JSON.parse(this.originalUser);
    }
  },
  data: () => ({
    resetPasswordDialog: false,
    resettingPassword: false,
    newPassword: '',
    originalUser: '',
    editUser: {},
    emptyUser: {
      email: '',
      firstName: '',
      lastName: '',
      role: null,
    },
    statuses: [
      {
        id: 1,
        name: 'Active',
      },
      {
        id: 2,
        name: 'Inactive',
      },
    ],
    projects: [],
    loadingProjects: false,
    projectsTableHeaders: [
      {
        text: 'Project Name',
        value: 'name',
      },
    ],
  }),
  methods: {
    ...mapActions([
      'loadUsers',
      'loadRoles',
      'getUser',
      'updateUser',
    ]),
    async save() {
      const originalUser = JSON.parse(this.originalUser);

      const changed = Object.entries(this.editUser)
        .filter(([prop, value]) => (
          prop === 'id' || JSON.stringify(value) !== JSON.stringify(originalUser[prop])
        ));

      await this.updateUser(Object.fromEntries(changed));

      this.$router.push({ name: 'admin:users' });
    },
    reset() {
      this.resettingPassword = true;
      this.updateUser({
        id: this.editUser.id,
        password: this.newPassword,
      })
        .then(() => {
          this.resetPasswordDialog = false;
        })
        .finally(() => {
          this.resettingPassword = false;
        });
    },
  },
};
</script>
