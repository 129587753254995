import http from '@/common/http';
import notifier from '@/common/notifier';

export default {
  state: {
    sigicomSensorData: [],
    sigicomSensorDataById: {},
    loadingSigicomSensorDataById: {},
    loadingSigicomSensorData: false,
  },
  mutations: {
    setSigicomSensorDataById(state, { id, data }) {
      state.sigicomSensorDataById = {
        ...state.sigicomSensorDataById,
        [id]: data,
      };
    },
    setSigicomSensorData(state, sigicomSensorData) {
      state.sigicomSensorData = sigicomSensorData;
    },
    setLoadingSigicomSensorData(state, loading) {
      state.loadingSigicomSensorData = loading;
    },
  },
  actions: {
    async loadSigicomSensorData({ commit }, { id, start, end }) {
      if (!id) {
        console.error('Attempted to load sigicom data without id.');
        notifier.notifyError('Failed to load sigicom sensor data.');
        return;
      }

      commit('setLoadingSigicomSensorData', true);

      try {
        const response = await http.get(`/sigicom-data/${id}`, {
          params: {
            start,
            end,
          },
        });
        commit('setSigicomSensorData', response.data || {});
        commit('setSigicomSensorDataById', { id, data: response.data });
      } catch (e) {
        console.error(e);
        notifier.notifyError('Failed to load sigicom sensor data.');
      } finally {
        commit('setLoadingSigicomSensorData', false);
      }
    },
  },
};
