import http from '@/common/http';
import notifier from '@/common/notifier';

const initState = {
  loadingInitialState: true,
  profile: null,
  isLoggedIn: null,
  loadingLogin: false,
};

function setAuthTokenInClient() {
  http.interceptors.request.use((config) => {
    if (localStorage.token) {
      const { headers } = config;
      headers.Authorization = `Bearer ${localStorage.token}`;
    }
    return config;
  });
}

export default {
  state: initState,
  mutations: {
    setProfileData(state, profile) {
      state.profile = profile;
    },
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setLoadingLogin(state, loadingLogin) {
      state.loadingLogin = loadingLogin;
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      commit('setLoadingLogin', true);
      try {
        const response = await http.post('/v1/auth/email/login', { email, password });
        const { errors, token } = response.data;

        console.log({ errors, token });

        if (errors) {
          if (errors.email === 'emailNotExists') {
            notifier.notifyError('Failed to login. Account not found.');
          } else if (errors.password === 'incorrectPassword') {
            notifier.notifyError('Failed to login. Incorrect password.');
          }
        } else {
          if (!token) {
            throw Error('Login failed to retrieve token.');
          }

          localStorage.token = token;
          setAuthTokenInClient();
          const profileData = await http.get('/v1/auth/me');
          commit('setProfileData', profileData.data);
          commit('setIsLoggedIn', true);
        }
      } catch (e) {
        if (e.response) {
          const { errors } = e.response.data;
          if (errors.email === 'emailNotExists') {
            notifier.notifyError('Failed to login. Account not found.');
          } else if (errors.password === 'incorrectPassword') {
            notifier.notifyError('Failed to login. Incorrect password.');
          }
        } else {
          notifier.notifyError('Failed to login.');
        }
      } finally {
        commit('setLoadingLogin', false);
      }
    },
    async logout({ commit }) {
      delete localStorage.token;
      commit('setIsLoggedIn', false);
      commit('setProfileData', null);
    },
    forgotPassword(store, { email }) {
      return http.post('/v1/auth/forgot/password', { email });
    },
    async getProfile() {
      if (initState.loadingInitialState) {
        if (localStorage.token) {
          setAuthTokenInClient();
          try {
            const profileData = await http.get('/v1/auth/me');
            initState.profile = profileData.data;
            initState.isLoggedIn = true;
          } catch (e) {
            delete localStorage.token;
            initState.isLoggedIn = false;
          }
        } else {
          initState.isLoggedIn = false;
        }
        initState.loadingInitialState = false;
      }

      return initState.profile;
    },
  },
};
