import notifier from '@/common/notifier';
import http from '@/common/http';
import { models } from '@/common/constants';

const defaultState = {};
const mutations = {};
const actions = {};
const getters = {};

models.forEach((item) => {
  defaultState[item.modelStateName] = [];
  defaultState[item.loadingStateName] = false;
  defaultState[item.gettingStateName] = false;
  defaultState[item.creatingStateName] = false;
  defaultState[item.updatingStateName] = false;
  defaultState[item.deletingStateName] = false;

  mutations[item.modelMutatorName] = (state, data) => {
    if (Array.isArray(data)) {
      state[item.modelStateName] = data;
    } else {
      const byId = {};
      state[item.modelStateName].forEach((stateItem) => { byId[stateItem.id] = stateItem; });
      state[item.modelStateName] = Object.values({
        ...byId,
        [data.id]: data,
      });
    }
  };

  mutations[item.loadingMutatorName] = (state, data) => { state[item.loadingStateName] = data; };
  mutations[item.gettingMutatorName] = (state, data) => { state[item.gettingStateName] = data; };
  mutations[item.creatingMutatorName] = (state, data) => { state[item.creatingStateName] = data; };
  mutations[item.updatingMutatorName] = (state, data) => { state[item.updatingStateName] = data; };
  mutations[item.deletingMutatorName] = (state, data) => { state[item.loadingStateName] = data; };

  actions[item.loadActionName] = async ({ commit }) => {
    try {
      commit(item.loadingMutatorName, true);
      const response = await http.get(`/v1/${item.api}`);
      commit(item.modelMutatorName, response.data);
    } catch (e) {
      console.error(e);
      notifier.notifyError(`Failed to load ${item.name.toLocaleLowerCase()}s.`);
    } finally {
      commit(item.loadingMutatorName, false);
    }
  };

  actions[item.getActionName] = async ({ commit }, id) => {
    try {
      commit(item.gettingMutatorName, true);
      const response = await http.get(`/v1/${item.api}/${id}`);
      commit(item.modelMutatorName, response.data);
      return response.data;
    } catch (e) {
      console.error(e);
      notifier.notifyError(`Failed to get ${item.name.toLocaleLowerCase()}.`);
    } finally {
      commit(item.gettingMutatorName, false);
    }
    return null;
  };

  actions[item.createActionName] = async ({ commit }, model) => {
    try {
      commit(item.creatingMutatorName, true);
      const response = await http.post(`/v1/${item.api}`, model);
      commit(item.modelMutatorName, response.data);
      notifier.notifySuccess(`Successfully created ${item.name}.`);
      return response.data;
    } catch (e) {
      console.error(e);
      notifier.notifyError(`Failed to create ${item.name.toLocaleLowerCase()}.`);
    } finally {
      commit(item.creatingMutatorName, false);
    }
    return null;
  };

  actions[item.updateActionName] = async ({ commit }, model) => {
    try {
      commit(item.updatingMutatorName, true);
      const response = await http.put(`/v1/${item.api}/${model.id}`, model);
      commit(item.modelMutatorName, response.data);
      notifier.notifySuccess(`Successfully updated ${item.name}.`);
      return response.data;
    } catch (e) {
      console.error(e);
      notifier.notifyError(`Failed to update ${item.name.toLocaleLowerCase()}.`);
    } finally {
      commit(item.updatingMutatorName, false);
    }
    return null;
  };

  actions[item.deleteActionName] = async ({ commit, state }, model) => {
    const id = model.id || model;
    try {
      commit(item.deletingMutatorName, true);
      await http.delete(`/v1/${item.api}/${id}`);
      commit(item.modelMutatorName, state[item.modelStateName]
        .filter((stateItem) => stateItem.id !== id));
      notifier.notifySuccess(`Successfully deleted ${item.name}.`);
    } catch (e) {
      console.error(e);
      notifier.notifyError(`Failed to delete ${item.name.toLocaleLowerCase()}.`);
    } finally {
      commit(item.deletingMutatorName, false);
    }
  };

  // getters
  getters[item.getByIdName] = (state) => (id) => state[`${item.modelStateName}s`]?.find((stateItem) => stateItem.id === id);
  getters[item.getByName] = (state) => ({ attribute, value }) => state[`${item.modelStateName}s`]?.find((stateItem) => stateItem[attribute] === value);
});

// console.log(defaultState);
// console.log(getters);
// console.log(actions);
// console.log(mutations);

export default {
  state: defaultState,
  mutations,
  actions,
  getters,
};
