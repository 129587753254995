import Vue from 'vue';
import Notifications from 'vue-notification';
import DateRangePicker from 'vue2-daterange-picker';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import './plugins/chart';
import store from './store';
import router from './router';
import './mixins/global';
import './mixins';

Vue.component('date-range-picker', DateRangePicker);
Vue.config.productionTip = false;

Vue.use(Notifications);
Vue.use(Vuelidate);

export default {
  bus: new Vue(),
};

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
