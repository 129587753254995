import http from '@/common/http';

export default {
  state: {
    roles: [],
    loadingRoles: null,
  },
  mutations: {
    setLoadingRoles(state, loading) {
      state.loadingRoles = loading;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
  },
  actions: {
    async loadRoles({ commit }) {
      commit('setLoadingRoles', true);

      const response = await http.get('/roles');
      const roles = Object.entries(response.data)
        .filter(([, id]) => typeof id === 'number')
        .reduce((acc, [roleName, id]) => {
          acc[roleName] = id;
          return acc;
        }, {});

      commit('setRoles', roles);
      commit('setLoadingRoles', false);
    },
  },
};
