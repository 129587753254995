<template>
  <page-component>
    <template v-slot:content>
      <v-row>
        <v-col>
          <crud-table-component
            :items="users"
            :loading="loadingUsers"
            :headers="tableHeaders"
          >
            <template v-slot:title>
              Users
            </template>
          </crud-table-component>
        </v-col>
      </v-row>
    </template>
  </page-component>
</template>

<script>
import modelMixins from '@/mixins/model-mixins.js';
import CrudTableComponent from '@/components/CrudTableComponent.vue';
import PageComponent from '@/components/PageComponent.vue';

export default {
  name: 'AdminView',
  mixins: [modelMixins.UserMixin],
  components: {
    CrudTableComponent,
    PageComponent,
  },
  mounted() {
    this.loadUsers();
  },
  data: () => ({
    tableHeaders: [
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'First name',
        value: 'firstName',
      },
      {
        text: 'Last name',
        value: 'lastName',
      },
      {
        text: 'Role',
        value: 'role.name',
      },
      {
        text: 'Status',
        value: 'status.name',
      },
    ],
  }),
};
</script>
