<template>
  <base-component :component="component" :dashboard="dashboard">
    <template v-slot:content>
      <v-hover
        v-slot="{ hover }"
      >
        <div :style="chartStyles" ref="parent">
          <div class="d-flex justify-center align-center" style="height: 100%; width: 100%"
            v-if="loadingData">
            <v-progress-circular
              indeterminate
              color="primary darken-2"
            ></v-progress-circular>
          </div>
          <chart-component
            ref="chart"
            :data="chartConfig.data"
            :options="chartConfig.options"
            :type="chartConfig.type"
          ></chart-component>
          <canvas
            :style="chartStyles" ref="target"
          ></canvas>
          <div
            style="position: relative; height: 50px; transition: margin 300ms;"
            :style="{
              marginTop: hover ? '-30px' : '30px'
            }"
          >
            <v-btn
              small
              @click="resetZoom"
              v-if="zoomEnabled"
            >Reset</v-btn>
          </div>
        </div>
      </v-hover>
    </template>
  </base-component>
</template>

<script>

import ChartComponent from '@/components/ChartComponent.vue';
import mixins from '@/mixins/model-mixins.js';
import dashboardComponentHelpers from '@/mixins/dashboard-component-helpers.js';
import chartRenderMixin from '@/mixins/chart-render.js';

export default {
  name: 'GraphComponent',
  mixins: [
    mixins.DataSetMixin,
    dashboardComponentHelpers,
    chartRenderMixin,
  ],
  components: {
    ChartComponent,
  },
  methods: {
    async fetchData() {
      const graphId = this.configuration?.graphId;
      if (!graphId) {
        this.notifyError('Dashboard graph missing graphId.');
        return;
      }

      const graph = this.getGraphById(graphId);

      if (!graph) {
        this.notifyError('Dashboard graph references non existing graph.');
      }

      this.chartConfig = await this.graphToChartConfiguration(graph, this.start, this.end);
    },
    resetZoom() {
      this.$refs.chart.reset();
    },
  },
  data: () => ({
    chartData: [],
    loadingData: false,
    chartConfig: {
      type: '',
      data: {},
      options: {},
    },
  }),
  mounted() {
    this.fetchData();
  },
  computed: {
    configuration() {
      return this.component?.configuration;
    },
    chartStyles() {
      return {
        'max-height': '100%',
        height: this.$refs.parent?.clientHeight || '100%',
        width: '100%',
        position: 'relative',
      };
    },
    zoomEnabled() {
      const zoom = this.chartConfig?.options?.plugins?.zoom;
      return zoom?.pan?.enabled
        || zoom?.zoom?.drag?.enabled
        || zoom?.zoom?.punch?.enabled
        || zoom?.zoom?.wheel?.enabled;
    },
  },
  watch: {
    start() {
      this.fetchData();
    },
    end() {
      this.fetchData();
    },
    configuration: {
      handler(newValue) {
        if (newValue) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
};
</script>
