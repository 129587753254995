import http from '@/common/http';
import notifier from '@/common/notifier';

export default {
  state: {
    jobs: [],
    loadingJobs: false,
  },
  mutations: {
    setLoadingJobs(state, loading) {
      state.loadingJobs = loading;
    },
    setJobs(state, jobs) {
      state.jobs = jobs;
    },
  },
  actions: {
    async loadJobs({ commit }, id) {
      try {
        commit('setLoadingJobs', true);
        const response = await http.get(`/jobs/${id}`);
        commit('setJobs', response.data);
      } catch (e) {
        console.error(e);
        notifier.notifyError('Failed to load jobs.');
      } finally {
        commit('setLoadingJobs', false);
      }
    },
  },
};
