import http from '@/common/http';
import notifier from '@/common/notifier';

export default {
  actions: {
    async getDataForMonitoringPoint(store, { id, start, end }) {
      if ([id, start, end].map((param) => typeof param).find((type) => type === 'undefined')) {
        console.error('Attempted to get data for monitoring point with incorrect parameters.');
        return notifier.notifyError('Failed to get data for monitoring point.');
      }
      const response = await http.get(`/v1/monitoring-points/${id}/data`, {
        params: { start, end },
      });

      return response.data;
    },
    async getDataForDataSet(store, {
      id,
      start,
      end,
      limit,
    }) {
      if ([id, start, end].map((param) => typeof param).find((type) => type === 'undefined')) {
        console.error('Attempted to get data for data set with incorrect parameters.');
        return notifier.notifyError('Failed to get data for monitoring point.');
      }

      const response = await http.get(`/v1/data-sets/${id}/data`, {
        params: { start, end, limit },
      });

      return response.data;
    },
    // async getDataForDataSource(store, { dataSourceId, dataId, start, end }) {
    //   if ([dataSourceId, dataId, start, end].map((param) => typeof param)
    // .find((type) => type === 'undefined')) {
    //     console.error('Attempted to get data for data source with incorrect parameters.');
    //     return notifier.notifyError('Failed to get data for data source.');
    //   }
    //   const response = await http.get(`/v1/data-sources/${dataSourceId}/data/${dataId}`, {
    //     params: { start, end },
    //   });

    //   return response.data;
    // },
  },
};
