import { models } from '@/common/constants';
import { mapActions, mapGetters, mapState } from 'vuex';

const modelMixins = models.map((model) => ({
  computed: {
    ...mapState(
      Object.fromEntries([
        model.modelStateName,
        model.creatingStateName,
        model.loadingStateName,
        model.deletingStateName,
        model.gettingStateName,
        model.updatingStateName,
      ].map((stateName) => [stateName, (state) => state.models[stateName]])),
    ),
    ...mapGetters([
      model.getByIdName,
      model.getByName,
    ]),
  },
  methods: {
    ...mapActions([
      model.createActionName,
      model.deleteActionName,
      model.getActionName,
      model.loadActionName,
      model.updateActionName,
    ]),
  },
}));

export const AllModelMixins = modelMixins;

const mixinMap = Object.fromEntries([
  ...models.map(({ pascalCase }, i) => [`${pascalCase}Mixin`, modelMixins[i]]),
  ...models.map(({ name }, i) => [name, modelMixins[i]]),
]);

export default mixinMap;
