<template>
  <page-component>
    <template v-slot:header>
      Home
    </template>
    <template v-slot:content>
      <v-row class="white--text">
        <v-col>
          <p>
            Welcome! The only things you can really do right now are configuring Telegraf,
            managing users, and viewing your files.
          </p>
        </v-col>
      </v-row>
    </template>
  </page-component>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue';

export default {
  name: 'HomeView',
  components: {
    PageComponent,
  },
  data: () => ({
    modules: [
      {
        name: 'Module 1',
        image: 'http://placekitten.com/300/300',
        to: '/module1',
      },
      {
        name: 'Module 2',
        image: 'http://placekitten.com/299/299',
        to: '/module2',
      },
      {
        name: 'Module 3',
        image: 'http://placekitten.com/298/298',
        to: '/module3',
      },
    ],
  }),
};
</script>
