<template>
  <page-component>
    <template v-slot:header>
      Telegraf Settings
    </template>
    <template v-slot:content>
      <v-row>
        <v-col cols="6">
          <v-card style="overflow-y: auto">
            <CodeMirrorComponent
              v-if="config"
              v-model="config"
              style="max-height: calc(100vh - 250px)"
            ></CodeMirrorComponent>
          </v-card>
          <v-btn class="mt-4" color="accent" @click="save" :loading="saving">Save</v-btn>
        </v-col>
        <v-col cols="6">
          <v-card tile>
            <v-card-text style="overflow:scroll; max-height: calc(100vh - 250px) ">
              <pre v-html="log"></pre>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </page-component>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue';
import http from '@/common/http';

export default {
  name: 'HomeView',
  components: {
    PageComponent,
  },
  created() {
    http.get('/v1/telegraf')
      .then((res) => {
        this.config = res.data;
      });
    this.getLogs();
    this.logInterval = setInterval(this.getLogs, 10000);
  },
  beforeDestroy() {
    clearInterval(this.logInterval);
  },
  data: () => ({
    config: '',
    log: '',
    saving: false,
    logInterval: null,
  }),
  methods: {
    async save() {
      try {
        this.saving = true;
        http.put('/v1/telegraf', { config: this.config });
        this.notifySuccess('Saved.');
      } catch (e) {
        this.notifyError('Failed to save.');
      } finally {
        this.saving = false;
      }
    },
    getLogs() {
      http.get('/v1/telegraf/logs')
        .then((res) => {
          this.log = res.data;
        });
    },
  },
};
</script>
