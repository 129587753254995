import * as axios from 'axios';

const url = new URL(window.origin);
const origin = process.env.VUE_APP_BACKEND_URI || `https://api.${url.host}`;

const client = axios.default.create({
  baseURL: `${origin}/api`,
  // timeout: 10000,
});

export default client;
