import Vue from 'vue';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import cronstrue from 'cronstrue';
import { Loader } from 'google-maps';
import { GOOGLE_API_KEY } from '@/common/constants';
import notifier from '../common/notifier';

import AboutComponent from '../components/info/AboutComponent.vue';
import DataSourceSelect from '../components/data-source/DataSourceSelect.vue';
import DataSourceDataIdCombobox from '../components/data-source/DataSourceDataIdCombobox.vue';
import MonitoringPointAutocomplete from '../components/common/MonitoringPointAutocomplete.vue';
import PageComponent from '../components/PageComponent.vue';
import BackButton from '../components/BackButtonComponent.vue';
import CodeMirrorComponent from '../components/CodeMirrorComponent.vue';
import SimpleColorPicker from '../components/graph/edit/SimpleColorPicker.vue';

const loader = new Loader(GOOGLE_API_KEY, {});

const actionsToBeMapped = [
  'getDataSourceData',
  'loadDataSourceDataIds',
  'getDataForDataSet',
];

const stateToBeMapped = {
  dataSourceDataIds: (state) => state.dataSources.dataSourceDataIds,
  loadingDataSourceDataIds: (state) => state.dataSources.loadingDataSourceDataIds,
  dataSourceData: (state) => state.dataSources.dataSourceData,
  loadingDataSourceData: (state) => state.dataSources.loadingDataSourceData,
};

// console.log({
//   actionsToBeMapped,
//   gettersToBeMapped,
//   stateToBeMapped,
// });

Vue.mixin({
  components: {
    AboutComponent,
    DataSourceSelect,
    DataSourceDataIdCombobox,
    PageComponent,
    BackButton,
    CodeMirrorComponent,
    MonitoringPointAutocomplete,
    SimpleColorPicker,
  },
  computed: {
    ...mapState({
      environmentName: (state) => state.auth.profile?.environment,
      ...stateToBeMapped,
    }),
  },
  methods: {
    randomUUID() {
      return crypto.randomUUID();
    },
    ...mapActions(actionsToBeMapped),
    shortDate(date) {
      return moment(date).format('lll');
    },
    filename(fullPath) {
      return fullPath.replace(/^.*[\\/]/, '');
    },
    ...notifier,
    cronglish(cron) {
      const text = cronstrue.toString(cron, {
        throwExceptionOnParseError: false,
        verbose: true,
        dayOfWeekStartIndexZero: false,
      });

      return `${text} (UTC)`;
    },
    createCopy(object) {
      return JSON.parse(JSON.stringify(object));
    },
    notImplemented() {
      this.notifyError('Not implemented');
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    // Because I keep forgetting the syntax
    removeAtIndex(array, index) {
      array.splice(index, 1);
    },
  },
  data: () => ({
    google: loader.load(),
    dgr: {
      email: (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
      required: (v) => !!v || 'Required.',
      minLength: (length) => ((v) => !v || v.length >= length || `Must be at least ${length} characters.`),
      noPeriod: (v) => !v || !/\./.test(v) || 'Must not contain a period.',
      noWhite: (v) => !v || !/\s/.test(v) || 'Must not contain spaces.',
    },
  }),
});
