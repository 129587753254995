import Vue from 'vue';
import Vuex from 'vuex';

import authModule from './auth';
import configModule from './config';
import dataModule from './data';
import dataSourcesModule from './dataSources';
// import graphsModule from './graphs';
import jobsModules from './jobs';
// import projectsModule from './projects';
import rolesModule from './roles';
import settingsModule from './settings';
import sigicomModule from './sigicom';
import sigicomSensorDataModule from './sigicomSensorData';
// import usersModule from './users';
import modelsModule from './models';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appName: 'DataGlance',
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth: authModule,
    config: configModule,
    data: dataModule,
    dataSources: dataSourcesModule,
    // graphs: graphsModule,
    jobs: jobsModules,
    // projects: projectsModule,
    roles: rolesModule,
    settings: settingsModule,
    sigicom: sigicomModule,
    sigicomSensorData: sigicomSensorDataModule,
    // users: usersModule,
    models: modelsModule,
  },
});
