<template>
  <canvas ref="target"></canvas>
</template>

<script>

import 'chartjs-adapter-moment';
import { Chart } from 'chart.js';

export default {
  props: ['data', 'options', 'type'],
  mounted() {
    const ctx = this.$refs.target.getContext('2d');

    this.chart = new Chart(ctx, {
      type: 'line',
      data: this.data,
      options: this.options,
    });
  },
  watch: {
    options: {
      handler(v) {
        this.chart.options = this.createCopy(v);
        this.chart.update();
      },
      deep: true,
    },
    data: {
      handler(v) {
        this.chart.data = this.createCopy(v);
        this.chart.update();
      },
      deep: true,
    },
    type(v) {
      this.chart.type = v;
      this.chart.update();
    },
  },
  computed: {},
  methods: {
    reset() {
      this.chart.resetZoom();
      this.chart.update();
    },
  },
};
</script>
